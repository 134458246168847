import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://twitter.com/KatiNovaes" className="icon alt fa-twitter" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
                <li><a href="https://katiuscianovaesdesa.vercel.app/" className="icon alt fa-star" target="_blank" rel="noopener noreferrer"><span className="label">Portfolio</span></a></li>
                <li><a href="mailto:katiuscianovaes@gmail.com" className="icon alt fa-envelope" target="_blank" rel="noopener noreferrer"><span className="label">Email</span></a></li>
                <li><a href="https://www.facebook.com/Redesign-WEB-104093648417278" className="icon alt fa-facebook" target="_blank" rel="noopener noreferrer"><span className="label">Facebook</span></a></li>
                <li><a href="https://t.me/katiuscianovaes" className="icon alt fa-telegram" target="_blank" rel="noopener noreferrer"><span className="label">Telegram</span></a></li>
                <li><a href="https://github.com/KatiusciaNovaesdeSa" className="icon alt fa-github" target="_blank" rel="noopener noreferrer"><span className="label">GitHub</span></a></li>
                <li><a href="https://www.linkedin.com/in/katiuscianovaesdesa/" className="icon alt fa-linkedin" target="_blank" rel="noopener noreferrer"><span className="label">LinkedIn</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; ReDesign</li><li>ReDesign: <a href="/">ReDesign</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
